<template>
  <b-card no-body class="p-0 m-0 rounded-0">
    <b-table
      :items="ctitleGroup"
      :fields="fields"
      ref="refInvoiceListTable"
      responsive
      :primary-key="ctitleGroup._id"
      show-empty
      empty-text="No records found"
      class="position-relative"
    >
      <template #cell(title)="data">
        <b-col>
          <router-link
            class="text-primary"
            :to="{
              name: data.item.hc_name.includes('oc-')
                ? 'dashboard-other-matter-preview'
                : 'dashboard-matter-preview',
              params: { id: data.item._id }
            }"
            target="_blank"
          >
            <h6 class=" text-primary">
              {{ data.item.title }}
            </h6>
          </router-link>
          <div v-if="Object.keys(data.item).includes('last_note')">
            <small v-if="data.item.last_note" class="text-black font-weight-bold">
              Note: {{ data.item.last_note }}
            </small>
          </div>
        </b-col>
      </template>
      <!-- Column: Unique Id -->
      <template #cell(unique_id)="data">
        <b-col>
          <small v-if="Object.keys(data.item).includes('file_no')"
            >File No.: {{ data.item.file_no }}</small
          >
          <div>
            <h5 class="font-weight-bold">
              {{ getUniqueId(data.item) }}
            </h5>
          </div>
          <div v-if="Object.keys(data.item).includes('cause_name')">
            <small v-if="data.item.cause_name">
              [{{ data.item.cause_name }}]
            </small>
          </div>
        </b-col>
      </template>
      <!-- Column: Unique Id -->
      <template #cell(court)="data">
        <b-col>
          <div v-if="Object.keys(data.item).includes('ctitle')">
            <small class="text-secondary font-italic">{{
              data.item.ctitle
            }}</small>
          </div>
          <div v-if="Object.keys(data.item).includes('causelist_type')">
            <small class="text-secondary" v-if="data.item.causelist_type"
              >({{ data.item.causelist_type }})</small
            >
          </div>
          <div v-if="Object.keys(data.item).includes('list_type')">
            <small class="text-secondary" v-if="data.item.list_type"
              >(List Type: {{ data.item.list_type }})</small
            >
          </div>
          <div v-if="Object.keys(data.item).includes('stats')">
            <div v-if="data.item.stats">
              <div
                v-if="
                  Object.keys(data.item.stats).includes('total_fresh_cases') ||
                  Object.keys(data.item.stats).includes('total_add_cases') ||
                  Object.keys(data.item.stats).includes('total_ia_cases') ||
                  Object.keys(data.item.stats).includes('ordinary') ||
                  Object.keys(data.item.stats).includes('urgent')
                "
              >
                <small
                  v-if="
                    data.item.stats.total_fresh_cases > 0 ||
                    data.item.stats.total_add_cases > 0 ||
                    data.item.stats.total_ia_cases > 0 ||
                    data.item.stats.ordinary > 0 ||
                    data.item.stats.urgent > 0
                  "
                >
                  (
                  <small
                    class="text-secondary"
                    v-if="
                      Object.keys(data.item.stats).includes('total_fresh_cases')
                    "
                    >F-{{ data.item.stats.total_fresh_cases }}
                  </small>
                  <small
                    class="text-secondary"
                    v-if="
                      Object.keys(data.item.stats).includes('total_add_cases')
                    "
                    >ADD-{{ data.item.stats.total_add_cases }}
                  </small>
                  <small
                    class="text-secondary"
                    v-if="
                      Object.keys(data.item.stats).includes('total_ia_cases')
                    "
                    >APP-{{ data.item.stats.total_ia_cases }}
                  </small>
                  <small
                    class="text-secondary"
                    v-if="Object.keys(data.item.stats).includes('ordinary')"
                    >ORD-{{ data.item.stats.ordinary }}
                  </small>
                  <small
                    class="text-secondary"
                    v-if="Object.keys(data.item.stats).includes('urgent')"
                    >URG-{{ data.item.stats.urgent }}
                  </small>
                  )
                </small>
              </div>
            </div>
          </div>
          <div
            v-if="
              Object.keys(data.item).includes('serial_number') ||
              Object.keys(data.item).includes('court_number')
            "
          >
            <small
              class="text-primary font-weight-bolder text-uppercase"
              v-if="
                data.item.court_number !== null || data.item.serial_number > 0
              "
            >
              Listed:
            </small>
            <small v-if="Object.keys(data.item).includes('serial_number')">
              <small
                class="text-primary font-weight-bolder text-uppercase"
                v-if="data.item.serial_number > 0"
              >
                [ Sr No. {{ data.item.serial_number }} ]
              </small>
            </small>
            <small v-if="Object.keys(data.item).includes('court_number')">
              <small v-if="checkIfInteger(data.item.court_number)">
                <small class="text-primary font-weight-bolder text-uppercase">
                  - [ Court No.
                  {{
                    data.item.court_number <= 0
                      ? getStringCourtNumber(data.item.court_number)
                      : data.item.court_number
                  }}
                  ]
                </small>
              </small>
              <small v-else>
                <small
                  class="text-primary font-weight-bolder text-uppercase"
                  v-if="data.item.court_number !== null"
                >
                  - [ Court No.
                  {{ data.item.court_number }}
                  ]
                </small>
              </small>
            </small>
            <small v-if="Object.keys(data.item).includes('transferred_court')">
              <small
                class="text-success font-weight-bolder"
                v-if="
                  data.item.transferred_court != null &&
                  data.item.transferred_court != -1
                "
              >
                - [ TF to CT
                {{
                  data.item.transferred_court <= 0
                    ? getStringCourtNumber(data.item.transferred_court)
                    : data.item.transferred_court
                }}
                ]
              </small>
            </small>
          </div>
          <div v-if="Object.keys(data.item).includes('judges')">
            <div v-if="data.item.judges">
              <small
                class="text-black font-weight-bold"
                v-if="data.item.judges.length"
                >{{
                  data.item.hc_name.includes('hc') ||
                  data.item.hc_name.includes('sci')
                    ? data.item.judges.length > 1
                      ? 'JUSTICES'
                      : 'JUSTICE'
                    : ''
                }}
                {{ data.item.judges.join(', ') }}
              </small>
            </div>
          </div>
          <div
            v-if="
              data.item.hc_name.includes('hc') ||
              data.item.hc_name.includes('sci')
            "
          >
            <small
              v-if="
                Object.keys(data.item).includes('serial_number') &&
                Object.keys(data.item).includes('court_number')
              "
            >
              <small
                v-if="
                  data.item.court_number !== null && data.item.serial_number > 0
                "
              >
                <hearing-display-court
                  :date="date"
                  :court_id="data.item.court_id"
                  :court_no="
                    Object.keys(data.item).includes('transferred_court') &&
                    data.item.transferred_court != null &&
                    data.item.transferred_court != -1
                      ? data.item.transferred_court
                      : data.item.court_number
                  "
                  :serial_no="data.item.serial_number"
                ></hearing-display-court>
              </small>
            </small>
          </div>
        </b-col>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-row class="text-nowrap">
          <b-col cols="auto" class="align-self-center col-8">
            <div class="text-right">
              <router-link
                class="mb-1 text-primary"
                :to="{
                  name: data.item.hc_name.includes('oc-')
                    ? 'dashboard-other-matter-preview'
                    : 'dashboard-matter-preview',
                  params: { id: data.item._id }
                }"
                target="_blank"
              >
                <b-badge variant="primary" pill class="badge-round mr-1">
                  <feather-icon
                    :id="`matter-${data.item._id}-preview-icon`"
                    icon="EyeIcon"
                    size="16"
                    class="mr-25"
                  />
                  <span>View</span>
                </b-badge>
              </router-link>
            </div>
            <div v-if="Object.keys(data.item).includes('last_order_url')">
              <b-badge
                variant="light"
                pill
                class="badge-round mt-2 cursor-pointer"
                v-if="data.item.last_order_url"
                @click="
                  exportJudgment({
                    url: data.item.last_order_url,
                    date: data.item.last_order.datetime,
                    matter_id: data.item.unique_id
                  })
                "
              >
                <feather-icon
                  :id="`order-${data.item._id}-preview-icon`"
                  icon="Link2Icon"
                  size="16"
                  class="mr-25 text-primary"
                />
                <span class="text-primary">Last Order</span>
              </b-badge>
            </div>
          </b-col>
        </b-row>
      </template>
    </b-table>
  </b-card>
</template>

<script>
// Your existing imports
import HearingDisplayCourt from '../../hearings/components/HearingDisplayCourt.vue'
import axios from 'axios'
import {
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BCard,
  BBadge,
  BButton,
  BTable,
  BCollapse,
  VBTooltip,
  VBToggle
} from 'bootstrap-vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BTable,
    BBadge,
    HearingDisplayCourt,
    BCollapse,
    BButton
    // Include any other components as necessary
  },
  directives: { 'b-tooltip': VBTooltip, 'b-toggle': VBToggle },
  props: {
    items: {
      type: Array,
      required: true
    },
    hearing_date: {
      type: String,
      required: true
    }
  },
  data() {
    let userData = JSON.parse(localStorage.getItem('userData'))
    return {
      ctitleGroup: this.items,
      date: this.hearing_date,
      fields: [
        { key: 'unique_id', label: 'Case Ref. Number', sortable: false },
        { key: 'court', label: 'Court', sortable: false },
        { key: 'title', sortable: false },
        { key: 'actions' }
      ],
      court: userData.court.label,
      collapseType: 'border'
    }
  },
  methods: {
    checkIfInteger(court_number) {
      // Parse the input as a number and check if it's an integer
      const parsedValue = parseInt(court_number, 10)
      return Number.isInteger(parsedValue)
    },
    navigateToDetail(data) {
      this.$router.push({
        name: data.hc_name.includes('oc-')
          ? 'dashboard-other-matter-preview'
          : 'dashboard-matter-preview',
        params: { id: data._id }
      })
    },
    downloadAs(url, name) {
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          responseType: 'blob'
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.setAttribute('download', name)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        })
        .catch((err) => {
          console.log('error', err)
        })
    },
    exportJudgment({ url, date, matter_id }) {
      let dd = date.toString().split('T')[0]
      let uu = matter_id.includes('-')
        ? matter_id.split('-').slice(2).join('/')
        : matter_id
      let href = url.replace(
        'http://s3.mymunshi.live',
        'https://app.mymunshi.in/exports'
      )
      this.downloadAs(href, `${uu}-order-${dd}.pdf`)
    },
    getStats(stats) {
      let sList = []
      let sResult = ''

      if (stats != null) {
        if (
          stats.hasOwnProperty('total_fresh_cases') &&
          stats['total_fresh_cases'] !== null &&
          typeof stats['total_fresh_cases'] === 'number' &&
          stats['total_fresh_cases'] > 0
        ) {
          sList.push('F-' + stats['total_fresh_cases'])
        }

        if (
          stats.hasOwnProperty('total_add_cases') &&
          stats['total_add_cases'] !== null &&
          typeof stats['total_add_cases'] === 'number' &&
          stats['total_add_cases'] > 0
        ) {
          sList.push('Add-' + stats['total_add_cases'])
        }

        if (
          stats.hasOwnProperty('total_ia_cases') &&
          stats['total_ia_cases'] !== null &&
          typeof stats['total_ia_cases'] === 'number' &&
          stats['total_ia_cases'] > 0
        ) {
          sList.push('App-' + stats['total_ia_cases'])
        }

        if (
          stats.hasOwnProperty('ordinary') &&
          stats['ordinary'] !== null &&
          typeof stats['ordinary'] === 'number' &&
          stats['ordinary'] > 0
        ) {
          sList.push('Ord-' + stats['ordinary'])
        }

        if (
          stats.hasOwnProperty('urgent') &&
          stats['urgent'] !== null &&
          typeof stats['urgent'] === 'number' &&
          stats['urgent'] > 0
        ) {
          sList.push('Urg-' + stats['urgent'])
        }
      }

      if (sList.length > 0) {
        sResult = '( '
        sResult += sList.join(' ')
        sResult += ' )'
      }

      return sResult
    }
  }
}
</script>

<style scoped>
.text-primary {
  color: #007bff;
}
.text-secondary {
  color: #6c757d;
}
</style>
